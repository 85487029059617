import { Controller } from "@hotwired/stimulus"
import { TurboConfirm } from "@rolemodel/turbo-confirm"

export default class extends Controller {
  #hasAccepted = false

  connect() {
    this.tc = new TurboConfirm({
      activeClass: "show"
    })
  }

  async perform(event) {
    if (this.#hasAccepted) {
      this.#hasAccepted = false
      return
    }

    event.preventDefault()
    event.stopImmediatePropagation()

    const title = event.params.title || "";
    const message = event.params.message || ""; 
    const confirmButton = event.params.confirmButton || null;

    try {
      const response = await this.tc.confirmWithContent({
        "#confirm-title": title,
        "#confirm-body": `${message} <p class="mt-4">${event.params.method === "delete" ? i18n.t("common.deleted_unrecoverable") : ""}</p>`,
        "#confirm-accept": confirmButton
      });

      if (response) {
        this.#hasAccepted = true
            event.target.closest('a').click();
      } else {
        $("#confirm").hide();
      }
    } catch (error) {
      console.error("Confirmation error:", error);
      $("#confirm").hide();
    }
  }
}

