// Configure your import map in config/importmap.rb. Read more: https://github.com/rails/importmap-rails

import * as Turbo from "@hotwired/turbo";
import { application } from "../controllers/application"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers";

// Uncomment to disable Turbo Drive if necessary
Turbo.session.drive = false;

// Load all Stimulus controllers
const context = require.context("../controllers", true, /controller\.js$/);
application.load(definitionsFromContext(context));
