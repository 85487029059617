//

export function initializeBatchActionsView(modifiedObjId, noWholeRowLink) {
    if (typeof csync !== 'undefined' && csync.batch_actions_views['response']) {
      csync.batch_actions_views['response'].undelegateEvents();
    }
  
    csync.batch_actions_views['response'] = new csync.Views.BatchActionsView({
      class_name: 'response',
      batch_ops: true,
      is_search: true
    }, csync.searchFormView);
  }
  